import { Link } from 'react-router-dom';
import banner from '../img/banner.png'
import '../styles/PageIndicator.modules.scss'


function PageIndicator(props) {

    return (

        <>
        <div className="page-indicator">
        <div className='blur-bg'>
            <img src={banner} alt="" />
        </div>
        <div className='text-holder'>
            <h1>{props.pageName}</h1>
            <div>
                <Link to={'/home'}>Home</Link> / <Link to={props.pageLink}>{props.pageName}</Link>
            </div>
        </div>
        </div>
        </>
    )
    
}

export default PageIndicator;