
import '../styles/home.modules.scss';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';


function Home() {

    function toTop(){
        window.scrollTo({
          top: 0,
          behavior: "auto"
        });
      console.log('afterEach')
      }

      useEffect(() => (
        toTop()
      ))

     



    return (
        <>
        <div className='landing-container'>
            <div className='landing-container-content'>
                <div>

                <h1> Your Trusted <span style={{color: '#4cb034'}} >Tree</span> Care Experts</h1>
                <button  className='btn'><Link style={{textDecoration: 'none', color: 'white'}} to='/about' >About Us</Link></button>
                </div>
            </div>
            
        </div>

        <div className='our-services'>
    
            <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}>

            <HashLink  className={'btn1'} to={'/services'}>Services</HashLink>
            </div>
        </div>

        </>
    )
}
export default Home