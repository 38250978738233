import ClampLines from "react-clamp-lines";
import house from '../img/house.jpg';
import PageIndicator from "../components/PageIndicator";
import { useEffect } from "react";
import '../styles/services.modules.scss';


function Services() {


    const services = [
        {
            id: 1,
            service: 'Residential',
            text: '',

        },
        {
            id: 2,
            service: 'Commercial',
            text: '',

        },
    
        
    ]

    const residentialService = [
        {
            id: 1,
            service: 'Thinning, or pruning, trees is a big part of our job. Knowing WHAT to trim, to promote the health of your trees.'
        },
        {
            id: 2,
            service: 'Stump removal'
        },
        {
            id: 3,
            service: 'Tree Removal'
        },
        {
            id: 4,
            service: 'Gutter cleaning'
        },
        {
            id: 5,
            service: 'We specialize in palm tree services'
        },
    ]

    const commercialService = [
        {
            id: 1,
            service: 'Thinning, or pruning, trees is a big part of our job. Knowing WHAT to trim, to promote the health of your trees.'
        },
        {
            id: 2,
            service: 'Stump removal'
        },
        {
            id: 3,
            service: 'Tree Removal'
        },
        {
            id: 4,
            service: 'Gutter cleaning'
        },
        {
            id: 5,
            service: 'We specialize in palm tree services'
        },
    ]


    function toTop(){
        window.scrollTo({
          top: 0,
          behavior: "auto"
        });
      console.log('afterEach')
      }

      useEffect(() => (
        toTop()
      ))


    return (
        <>
        <PageIndicator pageName={'services'} pageLink={'/services'}/>

        <div className='our-services'>
            <h1>
                Our <span className="green">services</span>
            </h1>
            <div className='card-row'>
                {
                    services.map((card) => (
                        <div key={card.id}>
                            <img src={house} alt="logo" />
                            <h1>{card.service}</h1>
                            <ClampLines
                  text={card.text}
                  id="custom"
                  lines={2}
                  moreText="Read More"
                  lessText="Read less"
                  className="custom-class"
                  ellipsis="..."
                  innerElement="p" />
                        </div>
                    ))
                }

            </div>
        </div>

        <div className="header-list">
            <h1>
            RESIDENTIAL SERVICES
            </h1>
            <ul>
                {
                    residentialService.map((list) => (
                        <li key={list.id}>{list.service}</li>
                    ))
                }
            </ul>
        </div>

        <div className="header-list">
            <h1>
            COMMERCIAL SERVICES
            </h1>
            <ul>
                {
                    commercialService.map((list) => (
                        <li key={list.id}>{list.service}</li>
                    ))
                }
            </ul>
        </div>

        </>
    )
}
export default Services;