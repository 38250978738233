import '../styles/footer.modules.scss'
import {Link} from 'react-router-dom'
import { AiOutlineInstagram } from 'react-icons/ai'



function Footer() {
    


    return(
        <>
        <footer>
            <div className="first-footer">
            <div className="second-footer">
            </div>
                <div>
                    <ul>
                        <label htmlFor="">
                            Phone:<br/>
                            <small>
                            <a href="tel:9257275937">  (925) 727-5937 </a>  
                            </small>
                        </label>
                        <label htmlFor="">
                            Email:<br/>
                            <a href="mailto:mvptreeservice101@gmail.com">mvptreeservice101@gmail.com</a>
                        </label>
                        <label htmlFor=""></label>
                    </ul>
                </div>
                <div>
                    <ul>
                        <label htmlFor="">
                            Quick Links
                        </label>

                        <small><Link to={'/services'}>Services</Link></small>
                        <small><Link to={'/about'}> About</Link></small>
                        <small><Link to={'/contact'}> Contact</Link></small>
                    </ul>
                </div>
                <div>
                    <ul>
                        <label htmlFor="">
                            Social
                        </label>
                        <label htmlFor="">
                            Follow us on Instagram 
                        </label>
                        <div className='icon-container'>

                        <button><a href="https://www.instagram.com/mvp_tree_service"><AiOutlineInstagram/></a></button>
                        </div>
                    </ul>
                </div>
            </div>
        </footer>
        </>
    )
}


export default Footer;