import { Link } from 'react-router-dom';
import '../styles/thankyou.modules.scss'


function ThankYou() {


    return(
        <>
        <div className='thankyou_body'>

        <div class="thankYou">
    <h1>Thank You! for contacting <span className='green'>MVP Tree Service</span></h1>
    <p>
        We will get back to you as soon as possible!<br/> 
    </p>
    <div class="button-container">
        <Link className='btn' to="/">
            Home
        </Link>
        <Link className='btn' to="/contact">
            Send another message!
        </Link>
    </div>
</div>
        </div>
        </>
    )
}

export default ThankYou;