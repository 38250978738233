import { useEffect } from "react";
import { Link } from "react-router-dom";
import PageIndicator from "../components/PageIndicator"
import tree1 from '../img/2Palms.png'
import tree2 from '../img/onTree.png'
import tree3 from '../img/2Palms.png'
import tree4 from '../img/trees.png'
import '../styles/about.modules.scss'





function About() {
    
    
    
    function toTop(){
        window.scrollTo({
          top: 0,
          behavior: "auto"
        });
      console.log('afterEach')
      }

      useEffect(() => (
        toTop()
      ))

    return (
        <>
        <PageIndicator pageName={'about us'} pageLink={'/about'}/>

        <div className="header-list">
        <h1>Our Mission</h1>
        <p classname="text">
        We are a family-owned business serving our community, and the entire Bay
        Area. MVP Tree Services was founded in 2015 in order help our community with their overgrown trees.
        Since then, we have made it our mission to provide 
        reliable service for homeowners in the Bay Area and the surrounding areas. Call us today for a free estimate! Hablamos español!
         <a href="tel:9257275937">  (925) 727-5937 </a> 
        </p>

        </div>

        <div className='our-services '>
            <div style={{display: 'flex', justifyContent: 'center'}}>

            <Link  className={'btn'} to={'/contact'}>Contact Us</Link>
            </div>
        </div>
    
        
        <div className="organogram-container" >
            
            <img src={tree1} alt="" />
        </div>

        <div className="organogram-container" >
            <img src={tree2} alt="" />
        </div>

        <div className="organogram-container" >
            <img src={tree3} alt="" />
        </div>

        <div className="organogram-container" >
            <img src={tree4} alt="" />
        </div>

        


        </>
    )
}
export default About