import { Outlet } from 'react-router-dom'
import '../styles/contact.modules.scss'
import PageIndicator from '../components/PageIndicator'
import { MdOutlineLocalPostOffice} from 'react-icons/md'
import { TfiTablet} from 'react-icons/tfi'
import { useEffect, useState } from 'react'


function Contact() {
    const [messageErr, setMessageErr] = useState('')
    const [nameErr, setNameErr] = useState('')
    const [emailErr, setEmailErr] = useState('')

    const form = [
        {
            id: 'message',
            type: 'text',
            name: 'comment',
            fieldType: 'textarea',
            class: '',
            value: '',
            placeholder: 'Enter Message'
        },
        {
            id: 'fullname',
            type: 'text',
            name: 'fullname',
            fieldType: 'input',
            class: 'half-width',
            value: '',
            placeholder: 'Enter your name'
        },
        {
            id: 'email',
            type: 'email',
            name: 'email',
            fieldType: 'input',
            class: 'half-width',
            value: '',
            placeholder: 'Enter email address'
        },
        {
            id: 'phone',
            type: 'text',
            name: 'number',
            fieldType: 'input',
            class: '',
            value: '',
            placeholder: 'Enter your phone number'
        },
        {
            id: 'subject',
            type: 'text',
            name: 'subject',
            fieldType: 'input',
            class: '',
            value: '',
            placeholder: 'Enter Subject'
        }
        
    ]


    const [formFields, updateFormFields] = useState(form);

    function handleChange(value, index) {
        let clonedFormFields = [...formFields]
        clonedFormFields[index].value = value;

        updateFormFields(clonedFormFields)
    }

    function toTop(){
        window.scrollTo({
          top: 0,
          behavior: "auto"
        });
      console.log('afterEach')
      }

      useEffect(() => (
        toTop()
      ), [])
        
    function handleSubmit(e){
        formFields.forEach(element => {
            
            if (element.id === 'message') {
                if (element.value === '') {
                    
                    e.preventDefault()
                    setMessageErr('message is required')
                }else{
                    setMessageErr('')
                    return true
                }
            }
            
            if( element.id === 'fullname' ) {
                if (element.value === '') {
                    setNameErr('name is required')
                    e.preventDefault()
                }else{
                    setNameErr('')
                    return true;
                }
            }
            if( element.id === 'email') {
                if (element.value === '') {
                    setEmailErr('Email is required')
                    e.preventDefault()
                }else{
                    setEmailErr('')
                    return true;
                }
            }
            
        });
        }

        useEffect(() => {
            formFields.forEach(element => {
            
                if (element.id === 'message') {
                    if (element.value !== '') {
                        
                        setMessageErr('')
                        
                    }
                }
                
                if( element.id === 'fullname' ) {
                    if (element.value !== '') {
                        setNameErr('')
                        
                    }
                }
                if( element.id === 'email') {
                    if (element.value !== '') {
                        setEmailErr('')
                        
                    }
                }
                
            });
        }, [formFields])

    return (
        <>
        <PageIndicator pageName={'contact'} pageLink={'/contact'}/>


        <div className='contact-form-info' >
            <h1>Get in Touch</h1>
            <div className='form-info'>
                <div className='form-container'>
                    <form action='https://formspree.io/f/xrgndobg' method='post'>
                        {
                            formFields.map((field, index) => (
                                field.fieldType === 'input' ?
                                <div className={field.class} >
                                <input name={field.name} onChange={event => handleChange(event.target.value, index)} value={field.value} placeholder={field.placeholder}  key={field.id} type={field.type} />
                                <span >{field.id === 'fullname'? nameErr: field.id === 'email'? emailErr: null }</span>
                                </div>:
                                <div className={field.class} >
                                <textarea name={field.name} onChange={event => handleChange(event.target.value, index)} value={field.value} placeholder={field.placeholder} key={field.id} id="" cols="30" rows="12"></textarea>
                                <span >{messageErr}</span>
                                </div>
                            ))
                        }
                    <button onClick={(event) => handleSubmit(event)} type='submit' name='submit' className='btn'>SEND MESSAGE</button>
                    </form>
                </div>
                <div className='info-container'>
                      

                        <div>
                            <MdOutlineLocalPostOffice className='contact-icon'/>
                            <div>
                                <p>
                                <a href="tel:9257275937">  (925) 727-5937 </a> <br/>
                                    <span>7 Days a Week 6am to 10pm</span>
                                </p>
                            </div>
                        </div>

                        <div>
                            <TfiTablet className='contact-icon'/>
                            <div>
                                <p>
                                <a href="mailto: mvptreeservice101@gmail.com"> mvptreeservice101@gmail.com</a> 
                                <br/>
                                    <span>Send us an email anytime!</span>
                                </p>
                            </div>
                        </div>
                </div>
            </div>
        </div>
        <Outlet/>
        </>
    )
}
export default Contact